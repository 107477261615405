



























































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { vxm } from '@/store'
import { VERSION, BUILD_NUMBER } from '@/config'

@Component({
  layout: 'unAuthenticated',
})
export default class Login extends Vue {
  $refs: Vue['$refs'] & {
    email: HTMLInputElement
    loginForm: {
      validate: any
    }
    forgotPasswordForm: {
      validate: any
    }
  }

  sites = []
  email = ''
  password = ''
  loading = false
  version = `${VERSION} ${BUILD_NUMBER}`
  private rules = {}

  get hasToken() {
    return vxm.user.hasToken
  }

  get forgot() {
    return /Forgot$/.test(this.$route.name)
  }

  get routes() {
    const prefix = /^NoSite\//.test(this.$route.name) ? 'NoSite/' : ''
    return {
      forgot: {
        name: prefix + 'Forgot',
        query: this.$route.query,
      },
      login: {
        name: prefix + 'Login',
        query: this.$route.query,
      },
    }
  }

  created() {
    if (this.forgot) {
      this.$emit('back', { name: 'login', query: this.$route.query })
    } else {
      this.$emit('back', false)
    }
    if (vxm.user.hasToken) {
      this.redirect()
    }

    this.rules = {
      email: [
        (v) => !!v || this.$t('c:validation:This field is required'),
        (v) => /.+@.+\..+/.test(v) || this.$t('c:validation:E-mail is not valid'),
      ],
      password: [(v) => !!v || this.$t('c:validation:This field is required')],
    }
  }

  @Watch('hasToken')
  onLoggedInChanged() {
    if (vxm.user.hasToken) {
      this.redirect()
    }
  }

  @Watch('forgot')
  onForgotChanged() {
    if (this.forgot) {
      this.$emit('back', { name: 'login', query: this.$route.query })
    } else {
      this.$emit('back', false)
    }
  }

  redirect() {
    const path = (this.$route.query.redirect as string) || '/'
    this.$router.push(path)
  }

  submit() {
    if (this.$refs.loginForm.validate()) {
      let email = this.email
      email = email.includes(':') ? email.split(':')[1] : email

      this.loading = true
      this.$axios
        .post('/v3/auth', {
          email,
          password: this.password,
        })
        .then((response) => response.data.data)
        .then((data) => {
          vxm.user.setTokens(data.tokens)
          this.redirect()
        })
        .catch(() => {
          vxm.alert.error({
            content: this.$t('Your email or your password is incorrect') as string,
            title: this.$t('c:common:Error') as string,
          })
        })
        .finally(() => {
          this.loading = false
        })
      return false
    }
  }

  forgotPw() {
    if (this.$refs.forgotPasswordForm.validate()) {
      let email = this.email
      const siteId = email.includes(':') ? email.split(':')[0] : -1
      email = email.includes(':') ? email.split(':')[1] : email
      const data: { email?; siteId? } = {
        email,
      }
      if (siteId > 0) {
        data.siteId = siteId
      }
      this.$axios
        .post('/v3/user/forgot', data)
        .then((response) => {
          vxm.alert.info({
            content: this.$t('An reset email has been sent to your email') as string,
            title: this.$t('Sent') as string,
          })
          this.$router.push('login')
        })
        .catch((reason) => {
          vxm.alert.warning({
            content: this.$t('There was an error trying to send the reset email') as string,
            title: this.$t('c:common:Error') as string,
          })
          this.$refs.email.focus()
        })
      return false
    }
  }
}
