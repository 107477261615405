





















































































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import PageTab from '@/components/ui/PageTab.vue'
import { vxm } from '@/store'
import { Watch } from 'vue-property-decorator'
import { dateTimeStringToUtcString, formatDateTimeToLocalDateTime } from '@/utils/dateUtils'

@Component({
  components: {
    ListView,
    PageTab,
  },
})
export default class List extends Vue {
  private listViewKey = 0
  private loadingData = true
  private loading = false

  private sites = []
  private chains = []

  private fetchedSites = []
  private filteredSites = []
  private fetchedChains = []

  private showContentEnglish = true
  private showContentSwedish = true
  private showContentNorwegian = true

  private titleEnglish = ''
  private titleSwedish = ''
  private titleNorwegian = ''
  private contentEnglish = ''
  private contentSwedish = ''
  private contentNorwegian = ''
  private publishDate = null
  private publishTime = null

  private notificationDialog = false
  private notificationPopup = false
  private notificationPopupItem = null

  private showSiteSelect = true
  private showChainSelect = false
  private showResellers = false

  private dateMenu = false
  private timeMenu = false

  private headers = []
  private rowActions = []
  private topActions = []

  private selectedResellerIds = []

  private selectionMode = 'site'

  private showAllOptions = false
  private showPartiallyOptions = false

  private notificationsTo = 'all_sites'

  private minDate = new Date().toISOString().substr(0, 10)

  private systemCreatedNotifications = false

  private deleteNotificationDialog = false

  private notificationIdToDelete = null

  created() {
    this.setHeaders()
    this.$axios.get(this.url).then(({ data: { sites, chains, showResellers } }) => {
      this.showResellers = showResellers
      if (sites.length !== 0 && chains.length !== 0) {
        this.showAllOptions = true
      } else if (sites.length !== 0 && chains.length === 0) {
        this.showPartiallyOptions = true
      }
      this.fetchedSites = sites.map((s) => ({
        id: s._id,
        name: s.name + ' (p' + s._id + ')',
        resellerId: s.reseller_id,
      }))
      this.filteredSites = this.fetchedSites

      this.fetchedChains = Object.entries(chains).map(([name, id]) => ({
        id,
        name,
      }))

      this.loadingData = false
    })

    this.rowActions = [
      {
        id: 'show',
        click: (_, item) => {
          this.notificationPopup = true
          this.notificationPopupItem = item
        },
      },
      {
        id: 'delete',
        click: (_, item) => {
          this.notificationIdToDelete = item.id
          this.deleteNotificationDialog = true
        },
      },
    ]

    this.topActions = [
      {
        id: 'new',
        template: 'new',
        click: (_) => {
          this.newNotificationDialog()
        },
      },
      {
        id: 'show',
        label: 'c:notification-feature:Show system created notifications',
        click: (_) => {
          this.systemCreatedNotificationsToggle()
        },
      },
    ]
  }

  private systemCreatedNotificationsToggle() {
    this.loading = true

    this.systemCreatedNotifications = !this.systemCreatedNotifications
    this.topActions = [
      {
        id: 'new',
        template: 'new',
        click: (_) => {
          this.newNotificationDialog()
        },
      },
      {
        id: 'show',
        label: this.systemCreatedNotifications
          ? 'c:notification-feature:Show user created notifications'
          : 'c:notification-feature:Show system created notifications',
        click: (_) => {
          this.systemCreatedNotificationsToggle()
        },
      },
    ]

    this.loading = false
  }

  @Watch('selectedResellerIds', { immediate: true })
  private onResellerIdChange() {
    this.filteredSites =
      this.selectedResellerIds.length === 0
        ? this.fetchedSites
        : this.fetchedSites.filter((site) => this.selectedResellerIds.includes(site.resellerId))
  }

  private get maxDate() {
    const today = new Date()
    const maxDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())
    return maxDate.toISOString().substr(0, 10)
  }

  private submitForm(): void {
    const isNameEmpty =
      (this.showContentEnglish && this.titleEnglish === '') ||
      (this.showContentSwedish && this.titleSwedish === '') ||
      (this.showContentNorwegian && this.titleNorwegian === '')
    const isContentEmpty =
      (this.showContentEnglish && this.contentEnglish === '') ||
      (this.showContentSwedish && this.contentSwedish === '') ||
      (this.showContentNorwegian && this.contentNorwegian === '')
    const isAllEmpty = !this.showContentEnglish && !this.showContentSwedish && !this.showContentNorwegian

    if (isNameEmpty || isContentEmpty || isAllEmpty) {
      vxm.alert.error({
        content: 'Please fill the whole form',
        title: this.$t('c:common:Error') as string,
      })
    } else {
      if (this.publishDate === null) {
        this.publishDate = new Date().toISOString().slice(0, 10)
      }

      if (this.publishTime === null) {
        const now = new Date()
        const hours = now.getHours().toString().padStart(2, '0')
        const minutes = now.getMinutes().toString().padStart(2, '0')
        this.publishTime = `${hours}:${minutes}`
      }

      const publishTime = this.publishDate + ' ' + this.publishTime

      const notificationData = {
        publishTime: dateTimeStringToUtcString(publishTime),
        isSupport: false,
        createdAtSiteId: null,
        forSites: this.showSiteSelect && this.showAllOptions,
        forChains: this.showChainSelect && this.showAllOptions,
        sites: '',
        chains: '',
        isAllSites: this.notificationsTo === 'all_sites',
        resellerIds: JSON.stringify(this.selectedResellerIds),
      }

      const languages = ['English', 'Swedish', 'Norwegian']

      languages.forEach((lang) => {
        const showContent = this[`showContent${lang}`]
        notificationData[`content${lang}`] = showContent ? this[`content${lang}`] : ''
        notificationData[`title${lang}`] = showContent ? this[`title${lang}`] : ''
      })

      if (this.showAllOptions) {
        if (this.showSiteSelect) {
          notificationData.sites = JSON.stringify(this.sites)
        } else {
          notificationData.chains = JSON.stringify(this.chains)
        }
      } else if (this.showPartiallyOptions) {
        notificationData.sites = this.notificationsTo
      } else {
        notificationData.sites = 'own_site'
      }

      this.loading = true
      this.$axios
        .post('v4/site/notifications', notificationData)
        .then(() => {
          this.listViewKey += 1
          this.notificationDialog = false
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error loading notifications')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private newNotificationDialog(): void {
    this.notificationDialog = true
  }

  private get url() {
    return `/v4/site/notifications/get-for-owner/${this.systemCreatedNotifications}`
  }

  private setHeaders() {
    this.headers = [
      {
        text: 'c:notification-list:Notification ID',
        value: 'id',
        filter: { disable: false },
      },
      {
        text: 'c:notification-list:English Title',
        value: 'titleEnglish',
        filter: { disable: false },
      },
      {
        text: 'c:notification-list:Swedish Title',
        value: 'titleSwedish',
        filter: { disable: false },
      },
      {
        text: 'c:notification-list:Norwegian Title',
        value: 'titleNorwegian',
        filter: { disable: false },
      },
      {
        text: 'c:notification-list:Publish Time',
        value: 'publishTime',
        sortable: false,
        filter: { disable: true },
      },
      {
        text: 'c:notification-list:Actions',
        value: 'actions',
      },
    ]
  }

  @Watch('selectionMode')
  private onSelectChange() {
    this.showSiteSelect = this.selectionMode === 'site'
    this.showChainSelect = !this.showSiteSelect
    this.sites = this.showSiteSelect ? [] : this.sites
    this.chains = this.showChainSelect ? [] : this.chains
  }

  formatDate(date) {
    return formatDateTimeToLocalDateTime(date)
  }

  formatContent(content) {
    // Replace plain text URLs with anchor tags
    const formattedText = content.replace(
      /(https?:\/\/[^\s]+|www\.[^\s]+)/g,
      (match) => `<a href="${match}" class="blue--text" target="_blank">${match}</a>`,
    )

    return formattedText
  }

  private async deleteNotification() {
    this.loadingData = true
    await this.$axios
      .delete('/v4/site/notifications/' + this.notificationIdToDelete)
      .catch((error) => {
        vxm.alert.onAxiosError(error, 'Failed to delete notification')
      })
      .finally(() => {
        this.deleteNotificationDialog = false
        this.loadingData = false
      })
  }
}
