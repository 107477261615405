







































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import FetchData from '../../components/helpers/FetchData.vue'
import { vxm } from '@/store'
import DocumentDialog from '@/components/document/DocumentDialog.vue'
import ItemTags from '@/components/tag/ItemTags.vue'
import PageTab from '@/components/ui/PageTab.vue'
import DetailsData from '@/components/tyre-hotel/DetailsData.vue'
import OrderData from '@/components/tyre-hotel/OrderData.vue'
import DocumentData from '@/components/tyre-hotel/DocumentData.vue'
import HistoryData from '@/components/tyre-hotel/HistoryData.vue'
import ImagesData from '@/components/tyre-hotel/image-data/ImagesData.vue'
import TyreWheelData from '@/components/tyre-hotel/tyre-wheel-data/TyreWheelData.vue'
import WheelChangeData from '@/components/tyre-hotel/WheelChangeData.vue'
import { EventBus } from '@/components/tyre-hotel/event-bus/event-bus'
import TagsData from '@/components/tyre-hotel/TagsData.vue'
import Actions from '@/components/tyre-hotel/Actions.vue'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import TyreHotelWheelSet from '@/models/tyrehotel/TyreHotelWheelSet'
import TyreHotelWheelChange from '@/models/tyrehotel/TyreHotelWheelChange'
import Product from '@/models/product/Product'
import Order from '@/models/order/Order'
import NoteData from '@/components/tyre-hotel/NoteData.vue'
import PositionSummary from '@/models/stock/PositionSummary'
import TreadDepthThresholds from '@/models/tyre/TreadDepthThresholds'
import Car from '@/models/car/Car'
import SubTab from '@/models/ui/SubTab'
import Tab from '@/models/ui/Tab'

@Component({
  components: {
    NoteData,
    Actions,
    TagsData,
    WheelChangeData,
    TyreWheelData,
    ImagesData,
    HistoryData,
    DocumentData,
    OrderData,
    DetailsData,
    PageTab,
    ItemTags,
    DocumentDialog,
    FetchData,
  },
})
export default class Details extends Vue {
  $refs: Vue['$refs'] & {
    newWheelSetForm: {
      validate: any
      reset: any
    }
  }

  private tyreHotel: TyreHotel = null

  private loading = false

  private addWheelSetRules = {
    season: null,
    name: null,
  }

  private addWheelSetLoading = false
  private addWheelSetDialog = false

  private activeTab = 0
  private activeWheelSubTab = 0

  private newWheelSet = {
    tyreHotelId: null,
    season: null,
    name: null,
  }

  private leftSideShown = true

  private locations = []
  private treadDepthThresholds: TreadDepthThresholds

  private tabs = []

  private async created() {
    const tabNames = ['Wheels', 'Images', 'History']
    tabNames.forEach((tabName) => {
      const newTab = new Tab()
      newTab.text = tabName
      newTab.translateText = true
      this.tabs.push(newTab)
    })
    this.addWheelSetRules = {
      season: [(v) => !!v || this.$t('c:validation:This field is required')],
      name: [(v) => !!v || this.$t('c:validation:This field is required')],
    }

    try {
      const [locationsResponse] = await Promise.all([
        this.$axios.get('/v4/site/locations?includeMyEmpireSites=true'),
        this.fetchTyreHotel(),
      ])

      this.locations = locationsResponse.data.data
    } catch (err) {
      await vxm.alert.onAxiosError(err, 'Error reading data')
    }

    EventBus.$on('reFetchTyreHotels', () => {
      this.fetchTyreHotel()
    })
  }

  private async fetchTyreHotel() {
    this.loading = true

    try {
      const response1 = await this.$axios.get('/v4/site/tyre-hotels-v2/' + this.$route.params.tyreHotelId)
      if (response1.data.data) {
        this.tyreHotel = new TyreHotel(response1.data.data)

        let activeWheelSetIndex = 0
        for (let i = 0; i < this.tyreHotel.tyreHotelWheelSets.length; i++) {
          if (this.tyreHotel.tyreHotelWheelSets[i].isOnHotel) {
            activeWheelSetIndex = i
          }
        }
        this.activeWheelSubTab = activeWheelSetIndex
        this.tyreHotel.activeTyreHotelWheelSet = this.tyreHotel.tyreHotelWheelSets[this.activeWheelSubTab]

        const [response2, response3, response4, response5, ...positionResponses] = await Promise.all([
          this.$axios.get('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id + '/wheel-change'),
          this.$axios.get('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id + '/last-wheel-change'),
          this.$axios.get('/v4/site/tyre/tread-depth-threshold'),
          this.$axios.get('/v4/site/cars/' + this.tyreHotel.carId + '?byTyreHotelEmpire=1'),
          ...this.tyreHotel.tyreHotelWheelSets.map((tyreHotelWheelSet) =>
            this.$axios.get('/v4/site/tyre-hotels-v2/wheel-set/' + tyreHotelWheelSet.id + '/stock-positions'),
          ),
        ])

        this.tyreHotel.tyreHotelWheelChanges = TyreHotelWheelChange.fromArray(response2.data.data)
        this.treadDepthThresholds = new TreadDepthThresholds(response4.data.data)
        this.tyreHotel.car = new Car(response5.data.data)

        if (response3.data.data) {
          this.tyreHotel.lastTyreHotelWheelChange = new TyreHotelWheelChange(response3.data.data)
        }

        const positionRequests = positionResponses.map((response, index) => {
          const tyreHotelWheelSet = this.tyreHotel.tyreHotelWheelSets[index]
          const tyreHotelWheelSetIndex = this.tyreHotel.tyreHotelWheelSets.indexOf(tyreHotelWheelSet)

          if (response.data.data) {
            response.data.data.forEach((tyreHotelWheelPosition) => {
              const foundIndex = tyreHotelWheelSet.tyreHotelWheels.findIndex(
                (tyreHotelWheel) => tyreHotelWheel.id === tyreHotelWheelPosition.tyreHotelWheelId,
              )

              this.tyreHotel.tyreHotelWheelSets[tyreHotelWheelSetIndex].tyreHotelWheels[foundIndex].positionSummary =
                new PositionSummary(tyreHotelWheelPosition)
            })
          }
        })

        await Promise.all(positionRequests)
      }
    } catch (err) {
      await vxm.alert.onAxiosError(err)
    } finally {
      this.loading = false
    }
  }

  private productChanged(productId: number) {
    this.$axios.get('/v4/site/product/' + productId).then((response) => {
      if (response.data.data) {
        this.tyreHotel.productId = productId
        this.tyreHotel.product = new Product(response.data.data)
      }
    })
  }


  private get wheelSubTabs() {
    const result = []
    this.tyreHotel.tyreHotelWheelSets.forEach((tyreHotelWheelSet) => {
      const subTab = new SubTab()
      if (tyreHotelWheelSet.isWinter) {
        subTab.icon = 'fa-snowflake'
        subTab.iconColorNormal = 'light-blue'
        subTab.iconColorSelected = 'white'
        subTab.textClassNormal = 'light-blue--text'
        subTab.textClassSelected = 'white--text'
        subTab.backgroundNormalColor = 'light-blue'
      } else {
        subTab.icon = 'fa-sun'
        subTab.iconColorNormal = 'orange'
        subTab.iconColorSelected = 'white'
        subTab.textClassNormal = 'orange--text'
        subTab.textClassSelected = 'white--text'
        subTab.backgroundNormalColor = 'orange'
      }
      subTab.translateText = false
      subTab.text = tyreHotelWheelSet.name + ' (' + tyreHotelWheelSet.tyreHotelWheels.length + ')'
      result.push(subTab)
    })
    return result
  }

  private onTabChange(tabIndex: number) {
    this.activeTab = tabIndex
  }

  private onSubTabChange(wheelSubTabIndex: number) {
    this.activeWheelSubTab = wheelSubTabIndex
    this.tyreHotel.activeTyreHotelWheelSet = this.tyreHotel.tyreHotelWheelSets[wheelSubTabIndex]
  }

  private addWheelSet() {
    if (this.$refs.newWheelSetForm.validate()) {
      this.addWheelSetLoading = true
      this.newWheelSet.tyreHotelId = this.tyreHotel.id

      this.$axios
        .post('/v4/site/tyre-hotels-v2/wheel-set', this.newWheelSet)
        .then((response) => {
          this.addWheelSetDialog = false
          this.tyreHotel.tyreHotelWheelSets.push(new TyreHotelWheelSet(response.data.data))
          this.activeWheelSubTab = this.tyreHotel.tyreHotelWheelSets.length - 1
          this.$refs.newWheelSetForm.reset()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error adding wheel set')
        })
        .finally(() => {
          this.addWheelSetLoading = false
        })
    }
  }

  private onComponentChanged() {
    this.fetchTyreHotel()
  }

  private onChangedCar(carId: number) {
    this.$axios
      .get('/v4/site/cars/' + carId + '?byTyreHotelEmpire=1')
      .then((response) => {
        this.tyreHotel.car = new Car(response.data.data)
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }

  @Watch('$route', { deep: true })
  private routeChange() {
    this.onComponentChanged()
  }

  @Watch('tyreHotel.tyreHotelWheelSets')
  private onTyreHotelWheelSetsChanged() {
    const lastIndex = this.tyreHotel.tyreHotelWheelSets.length - 1
    if (this.activeWheelSubTab > lastIndex) {
      this.activeWheelSubTab = lastIndex
    }
    this.onSubTabChange(this.activeWheelSubTab)
  }

  @Watch('tyreHotel.lastTyreHotelWheelChange')
  private onLastTyreHotelWheelChange() {
    if (this.tyreHotel.lastTyreHotelWheelChange?.orderId) {
      this.$axios
        .get(
          '/v4/site/order/' +
            this.tyreHotel.lastTyreHotelWheelChange.orderId +
            '?siteId=' +
            this.tyreHotel.lastTyreHotelWheelChange.siteId,
        )
        .then((response) => {
          if (response.data.data) {
            this.tyreHotel.lastTyreHotelWheelChange.order = new Order(response.data.data)
          }
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }
}
