import Booking from '@/models/booking/Booking'

export default class Order {
  public id: number
  public siteId: number
  public customerId: number
  public payerId: number
  public carLicenseplate: string
  public tyreHotelId: number
  public grossAmount: number
  public currency: string
  public paymentStatus: string
  public bookingId: number
  public inStock: boolean
  public isCancelled: boolean
  public isPaid: boolean
  public isBilled: boolean
  public internalNote: string
  public customerReference: string
  public deliverTo: string
  public deliveryName: string
  public deliveryAddress1: string
  public deliveryAddress2: string
  public deliveryCity: string
  public deliveryCountry: string
  public deliveryZipcode: string
  public deliveryEmail: string
  public deliveryPhone: string
  public isStarred: boolean

  public booking: Booking = null

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.siteId = data.grossAmount ? (data.siteId as number) : null
    this.grossAmount = data.grossAmount ? (data.grossAmount as number) : null
    this.currency = data.currency ? (data.currency as string) : null
    this.paymentStatus = data.paymentStatus ? (data.paymentStatus as string) : null
    this.bookingId = data.bookingId ? (data.bookingId as number) : null
    this.inStock = data.inStock ? (data.inStock as boolean) : null
    this.isCancelled = data.isCancelled ? (data.isCancelled as boolean) : null
    this.isPaid = data.isPaid ? (data.isPaid as boolean) : null
    this.isBilled = data.isBilled ? (data.isBilled as boolean) : null
    this.customerId = data.customerId ? (data.customerId as number) : null
    this.payerId = data.payerId ? (data.payerId as number) : null
    this.carLicenseplate = data.carLicenseplate ? (data.carLicenseplate as string) : null
    this.tyreHotelId = data.tyreHotelId ? (data.tyreHotelId as number) : null
    this.internalNote = data.internalNote ? (data.internalNote as string) : null
    this.customerReference = data.customerReference ? (data.customerReference as string) : null
    this.deliverTo = data.deliverTo ? (data.deliverTo as string) : null
    this.deliveryName = data.deliveryName ? (data.deliveryName as string) : null
    this.deliveryAddress1 = data.deliveryAddress1 ? (data.deliveryAddress1 as string) : null
    this.deliveryAddress2 = data.deliveryAddress2 ? (data.deliveryAddress2 as string) : null
    this.deliveryAddress2 = data.deliveryAddress2 ? (data.deliveryAddress2 as string) : null
    this.deliveryCity = data.deliveryCity ? (data.deliveryCity as string) : null
    this.deliveryCountry = data.deliveryCity ? (data.deliveryCountry as string) : null
    this.deliveryZipcode = data.deliveryZipcode ? (data.deliveryZipcode as string) : null
    this.deliveryEmail = data.deliveryEmail ? (data.deliveryEmail as string) : null
    this.deliveryPhone = data.deliveryPhone ? (data.deliveryPhone as string) : null
    this.isStarred = data.isStarred ? (data.isStarred as boolean) : null
  }

  public clone(): Order {
    const result = new Order()
    Object.assign(result, this)
    return result
  }

  public getPaymentStatus(): string {
    if (this.isCancelled) {
      return 'Cancelled'
    } else if (this.isPaid) {
      return 'Paid'
    } else if (this.isBilled) {
      return 'Billed'
    } else {
      return 'Not Paid'
    }
  }

  public getFormattedGrossAmount(): string {
    const rounded = Math.round(parseFloat('' + this.grossAmount) * 100) / 100
    return rounded.toFixed(2)
  }
}
