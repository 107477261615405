
























































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import ListView from '@/components/list/ListView.vue'
import { vxm } from '@/store'
import b64toBlob from 'b64-to-blob'
import { appendSitePrefix } from '@/utils/routeUtils'
import { formatDateTimeString } from '@/utils/dateUtils'

@Component({
  methods: { appendSitePrefix, formatDateTimeString },
  components: { ListView },
})
export default class GenericReport extends Vue {
  public $refs: Vue['$refs'] & {
    reportFiltersForm: {
      validate: any
      reset: any
    }
  }

  private loading = false
  private exportExcelLoading = false
  private exportJsonLoading = false

  private dataFetched = false

  private title = ''
  private report = ''
  private reportType = ''

  private reports = []

  private filters = []

  private headers = []
  private tableData = []

  private customerSearch = ''
  private customerSearchLoading = false
  private customerSearchItems = []

  created() {
    this.loading = true
    this.report = this.$route.params.name
    this.fetchInitial()
  }

  private fetchInitial() {
    this.$axios
      .get('/v4/site/tyre-hotel/reports/' + this.report)
      .then((response) => {
        this.title = response.data.data.title
        this.reportType = response.data.data.type
        const filters = response.data.data.filters
        filters.forEach((filter) => {
          if (filter.options) {
            filter.options = this.transformOptions(filter.options)
            filter.value = filter.defaultValue
          }
          this.filters.push(filter)
        })

        this.$axios.get('/v4/site/tyre-hotel/reports').then((response) => {
          this.reports = response.data.data.data
        })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private openReport() {
    this.title = ''
    this.reportType = ''
    this.filters = []
    this.headers = []
    this.tableData = []
    this.dataFetched = false
    this.loading = true
    this.fetchInitial()
    this.$router.push({
      name: 'Reports/Generic',
      params: { name: this.report },
    })
  }

  @Watch('customerSearch')
  onCustomerSearchChanged() {
    this.customerSearchLoading = true
    this.$axios
      .get('v4/site/customers/search?search=' + this.customerSearch)
      .then((response) => {
        this.customerSearchItems = response.data.data
        this.customerSearchLoading = false
      })
      .finally(() => {
        this.customerSearchLoading = false
      })
  }

  private transformOptions(options) {
    return Object.entries(options).map(([id, name]) => ({ id, name }))
  }

  private reset() {
    this.openReport()
  }

  private executeReport() {
    if (this.$refs.reportFiltersForm.validate()) {
      this.headers = []
      this.tableData = []

      const params = {}

      this.filters.forEach((filter) => {
        params[filter.name] = filter.value || null
      })

      this.loading = true
      this.$axios
        .post(`/v4/site/tyre-hotel/reports/${this.report}/get-data`, params)
        .then((response) => {
          const fields = response.data.data.fields
          fields.forEach((field) => {
            if (field.name !== 'hotelProductId' || field.name !== 'id') {
              this.headers.push({ text: field.label, value: field.name })
            }
          })
          this.dataFetched = true
          this.tableData = response.data.data.data
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Error executing report')
          this.dataFetched = false
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  private downloadExcel() {
    this.exportExcelLoading = true
    const queryParams = []

    this.filters.forEach((filter) => {
      if (filter.value !== undefined && filter.value !== null && filter.value !== '') {
        queryParams.push(`${filter.name}=${encodeURIComponent(filter.value)}`)
      }
    })
    let url = '/v4/site/tyre-hotel/reports/' + this.report + '/get-excel'

    if (queryParams.length > 0) {
      url += '?' + queryParams.join('&')
    }

    this.$axios
      .get(url)
      .then((response) => {
        const blob = b64toBlob(
          response.data.data.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        )
        const filename = this.generateFilename('xlsx')
        if (!window.navigator.msSaveOrOpenBlob) {
          // BLOB NAVIGATOR
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', filename)
          document.body.appendChild(a)
          a.click()
        } else {
          // BLOB FOR EXPLORER 11
          window.navigator.msSaveOrOpenBlob(blob, filename)
        }
      })
      .finally(() => {
        this.exportExcelLoading = false
      })
  }

  private downloadJSON() {
    this.exportJsonLoading = true
    const filename = this.generateFilename('json')
    const blob = new Blob([JSON.stringify(this.tableData)], { type: 'application/json' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    this.exportJsonLoading = false
  }

  private generateFilename(extension) {
    const titleWithUnderscore = this.title.replace(/\s+/g, '_')

    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')

    const hours = String(currentDate.getHours()).padStart(2, '0')
    const minutes = String(currentDate.getMinutes()).padStart(2, '0')
    const seconds = String(currentDate.getSeconds()).padStart(2, '0')
    const datetime = `${year}${month}${day}_${hours}${minutes}${seconds}`

    return `${titleWithUnderscore}_${datetime}.${extension}`
  }
}
