import Vue, { AsyncComponent } from 'vue'
import Router, { RouteConfig as BaseRouteConfig } from 'vue-router'
import guards from './guards'
import { createRouterLayout } from 'vue-router-layout'
import Component from 'vue-class-component'
import { checkBuild } from '@/utils/routeUtils'

Vue.use(Router)

const RouterLayout = createRouterLayout((layout) => {
  return import('@/layouts/' + layout + '.vue')
})

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate'])

type RouteConfig<T> = BaseRouteConfig & {
  name: T
  component?: AsyncComponent
  children?: RouteConfig<T>[]
}

const unauthenticatedRoutes = [
  {
    path: 'login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: 'forgot',
    name: 'Forgot',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: 'forgot/:token',
    name: 'PasswordReset',
    component: () => import('@/views/PasswordReset.vue'),
  },
  {
    path: 'supplier/order',
    name: 'Supplier/Order',
    component: () => import('@/views/supplier/Order.vue'),
  },
  {
    path: 'email/unsubscribe',
    name: 'Email/Unsubscribe',
    component: () => import('@/views/email/UnSubscribe.vue'),
  },
  {
    path: 'payment/notification',
    name: 'Payment/Notification',
    component: () => import('@/views/payment/Notification.vue'),
  },
  {
    path: 'payment/invoice',
    name: 'Payment/Invoice',
    component: () => import('@/views/payment/Invoice.vue'),
  },
  {
    path: 'integrations/fortnox/before-auth',
    name: 'Integrations/Fortnox/BeforeAuthPage',
    component: () => import('@/views/economy/accounting-integration/fortnox/FortnoxSetupBeforeAuthPage.vue'),
  },
  {
    path: 'integrations/fortnox/after-auth',
    name: 'Integrations/Fortnox/AfterAuthPage',
    component: () => import('@/views/economy/accounting-integration/fortnox/FortnoxSetupAfterAuthPage.vue'),
  },
  {
    path: 'tread-depth/:token',
    name: 'Report/TreadDepthReading',
    component: () => import('@/views/public/tread-depth-reading/Show.vue'),
  },
  {
    path: 'billing/settlement/:token',
    name: 'Public/Billing/Settlement',
    component: () => import('@/views/public/billing/SettlementPublic.vue'),
  },
] as RouteConfig<string>[]

const authenticatedRoutes = (
  [
    {
      path: '',
      name: 'Dashboard',
      component: () => import('@/components/VueFrame.vue'),
      props: { url: '/' },
    },
    {
      path: 'search/:query',
      name: 'Search',
      component: () => import('@/views/Search.vue'),
    },
    {
      path: 'tyre_hotel_new/:detailed(detailed)?',
      name: 'TyreHotel',
      component: () => import('@/views/TyreHotel.vue'),
    },
    {
      path: 'tyre-hotel/new',
      name: 'TyreHotel/New',
      component: () => import('@/views/tyre-hotel/New.vue'),
    },
    {
      path: 'tyre-hotel/details/:tyreHotelId',
      name: 'TyreHotel/View',
      component: () => import('@/views/tyre-hotel/Details.vue'),
    },
    {
      path: 'tyre-hotel/list',
      name: 'TyreHotel/List',
      component: () => import('@/views/tyre-hotel/List.vue'),
    },
    {
      path: 'tyre-hotel/list2',
      name: 'TyreHotel/List2',
      component: () => import('@/views/tyre-hotel/List2.vue'),
    },
    {
      path: 'customer/edit/:id',
      name: 'Customers/Edit',
      component: () => import('@/views/Customer.vue'),
    },
    {
      path: 'axess/stock-import',
      name: 'Stock/Axess/StockUploader',
      component: () => import('@/views/integrations/AxessStockUploader.vue'),
    },
    {
      path: 'barcode-reader',
      name: 'BarcodeReader',
      component: () => import('@/views/TyreHotelInventoryFront.vue'),
    },
    {
      path: 'barcode-reader/hotel-quick',
      name: 'BarcodeReader/HotelQuick',
      meta: { hideNavBar: true },
      component: () => import('@/views/TyreHotelInventory2.vue'),
    },
    {
      path: 'barcode-reader/hotel-inventory',
      name: 'BarcodeReader/HotelInventory',
      component: () => import('@/views/TyreHotelInventory.vue'),
    },
    {
      path: 'admin/vehicles',
      name: 'Admin/Vehicles',
      component: () => import('@/views/custom-vehicle/CustomVehicleList.vue'),
    },
    {
      path: 'admin/vehicles/new',
      name: 'Admin/Vehicles/New',
      component: () => import('@/views/custom-vehicle/CustomVehicleEditor.vue'),
    },
    {
      path: 'admin/vehicles/:id',
      name: 'Admin/Vehicles/Edit',
      component: () => import('@/views/custom-vehicle/CustomVehicleEditor.vue'),
    },
    {
      path: 'resurs_bank/config',
      name: 'Integrations/ResursBank/Config',
      component: () => import('@/views/resurs-bank/ResursBankConfig.vue'),
    },
    {
      path: ':id/order2/resurs_bank',
      name: 'Integrations/ResursBank/Frame',
      component: () => import('@/views/resurs-bank/ResursBankFrame.vue'),
    },
    {
      path: 'integrations/fortus',
      name: 'Integrations/Fortus/Config',
      component: () => import('@/views/integrations/fortus/Config.vue'),
    },
    {
      path: 'integrations/dackia/config',
      name: 'Integrations/Dackia/Config',
      component: () => import('@/views/integrations/dackia/Config.vue'),
    },
    {
      path: 'integrations/dackia/purch-price-matrix',
      name: 'Integrations/Dackia/PurchPriceMatrix',
      component: () => import('@/views/integrations/dackia/PurchPriceMatrix.vue'),
    },
    {
      path: 'integrations/dackia/brands',
      name: 'Integrations/Dackia/Brands',
      component: () => import('@/views/integrations/dackia/BrandMapping.vue'),
    },
    {
      path: 'integrations/tread-depth-reader/new',
      name: 'Integrations/TreadDepthReader/New',
      component: () => import('@/views/integrations/tread-depth-reader/Form.vue'),
    },
    {
      path: 'integrations/tread-depth-reader/:id/edit',
      name: 'Integrations/TreadDepthReader/Edit',
      component: () => import('@/views/integrations/tread-depth-reader/Form.vue'),
    },
    {
      path: 'integrations/tread-depth-reader',
      name: 'Integrations/TreadDepthReader',
      component: () => import('@/views/integrations/tread-depth-reader/Form.vue'),
      meta: { all: true },
    },
    {
      path: 'supplier/invoice-attestation/:attestation_status?',
      name: 'Supplier/InvoiceAttestation',
      component: () => import('@/views/SupplierInvoiceAttestation.vue'),
    },
    {
      path: 'suppliers/invoices',
      name: 'Suppliers/Invoices',
      component: () => import('@/views/SupplierInvoices.vue'),
    },
    {
      path: 'suppliers/invoice/:id',
      name: 'Suppliers/Invoice',
      component: () => import('@/views/SupplierInvoice.vue'),
    },
    {
      path: 'mekonomen/orders/health-check',
      name: 'Orders/Mekonomen/HealthCheck',
      component: () => import('@/views/MekonomenOrdersHealthCheck.vue'),
    },
    {
      path: 'mekonomen/meca-price-tool',
      name: 'Admin/Mekonomen/MecaPriceTool',
      component: () => import('@/views/integrations/mekonomen-meca-price-tool/MekonomenMecaPriceTool.vue'),
    },
    {
      path: 'chain/customer-center',
      name: 'Customers/ChainCustomers',
      component: () => import('@/views/ChainCustomerCenter.vue'),
    },
    {
      path: 'customers/managed',
      name: 'Customers/Managed',
      component: () => import('@/views/customers/Managed.vue'),
    },
    {
      path: 'customers/list',
      name: 'Customers/List',
      component: () => import('@/views/customers/List.vue'),
    },
    {
      path: 'customers/supplier-consignee/list',
      name: 'Customers/SupplierConsignee/List',
      component: () => import('@/views/customers/supplier-consignee/List.vue'),
    },
    {
      path: 'customers/managers',
      name: 'Customers/Managers',
      component: () => import('@/views/customers/Managers.vue'),
    },
    {
      path: 'customers/bonus_group/list',
      name: 'Customers/BonusGroup/List',
      component: () => import('@/views/customers/bonus-group/List.vue'),
    },
    {
      path: 'customers/bonus_group/create',
      name: 'Customers/BonusGroup/Create',
      component: () => import('@/views/customers/bonus-group/Create.vue'),
    },
    {
      path: 'customers/bonus_group/edit/:id',
      name: 'Customers/BonusGroup/Edit',
      component: () => import('@/views/customers/bonus-group/Create.vue'),
    },
    {
      path: 'logistics',
      name: 'Stock/Logistics',
      component: () => import('@/views/logistics/Dashboard.vue'),
    },
    {
      path: 'logistics/admin',
      name: 'Stock/Logistics/Admin',
      component: () => import('@/views/logistics/Admin.vue'),
    },
    {
      path: 'stock/stock-refill/list',
      name: 'Stock/Stock-Refill/StockRefillConfiguration/List',
      component: () => import('@/views/stock/stock-refill/List.vue'),
    },
    {
      path: 'stock/stock-refill/details/:id',
      name: 'Stock/Stock-Refill/StockRefillConfiguration/Details',
      component: () => import('@/views/stock/stock-refill/Details.vue'),
    },
    {
      path: 'stock/reports',
      name: 'Stock/Reports',
      component: () => import('@/views/stock/reports/List.vue'),
    },
    {
      path: 'stock/reports/balance-adjustments',
      name: 'Stock/Reports/BalanceAdjustments',
      component: () => import('@/views/stock/reports/StockBalanceAdjustmentsReport.vue'),
    },
    {
      path: 'stock/reports/deliveries',
      name: 'Stock/Reports/Deliveries',
      component: () => import('@/views/stock/reports/StockDeliveriesReport.vue'),
    },
    {
      path: 'stock/reports/values',
      name: 'Stock/Reports/Values',
      component: () => import('@/views/stock/reports/StockValuesReport.vue'),
    },
    {
      path: 'stock/reports/value-items',
      name: 'Stock/Reports/ValueItems',
      component: () => import('@/views/stock/reports/StockValueItemsReport.vue'),
    },
    {
      path: 'stock/reports/inventories-with-diff-list',
      name: 'Stock/Reports/InventoriesWithDiffList',
      component: () => import('@/views/stock/reports/StockInventoriesWithDiffListReport.vue'),
    },
    {
      path: 'statistics/customers/orders/inactivity',
      name: 'Finance/Statistics/CustomersOrdersInactivity',
      component: () => import('@/views/statistics/customers/OrdersInactivity.vue'),
    },
    {
      path: 'statistics/groups/sales',
      name: 'Finance/Statistics/GroupSales',
      component: () => import('@/views/statistics/groups/Sales.vue'),
    },
    {
      path: 'reports/suppliers/purchases/report',
      name: 'Suppliers/Purchases/Reports/Report',
      component: () => import('@/views/reports/suppliers/purchases/Report.vue'),
    },
    {
      path: 'reports/suppliers/purchases/create',
      name: 'Suppliers/Purchases/Reports/Create',
      component: () => import('@/views/reports/suppliers/purchases/Create.vue'),
    },
    {
      path: 'reports/suppliers/purchases/list',
      name: 'Suppliers/Purchases/Reports',
      component: () => import('@/views/reports/suppliers/purchases/List.vue'),
    },
    {
      path: 'reports/tyre-hotel/replacements',
      name: 'Reports/TyreHotel/Replacements',
      component: () => import('@/views/reports/tyre-hotel/Replacements.vue'),
    },
    {
      path: 'reports/products/create',
      name: 'Reports/Products/Create',
      component: () => import('@/views/reports/products/Create.vue'),
    },
    {
      path: 'reports/products/edit/:id',
      name: 'Reports/Products/Edit',
      component: () => import('@/views/reports/products/Create.vue'),
    },
    {
      path: 'reports/products/list',
      name: 'Reports/Products/List',
      component: () => import('@/views/reports/products/List.vue'),
    },
    {
      path: 'reports/products/transfers/:reportProductId',
      name: 'Reports/Products/Transfers',
      component: () => import('@/views/reports/products/transfers/List.vue'),
    },
    {
      path: 'shop/data/budget',
      name: 'Admin/Shop/Budget',
      component: () => import('@/views/shop-data/Budget.vue'),
    },
    {
      path: 'statistics/general/sales',
      name: 'Statistics/General/Sales',
      component: () => import('@/views/statistics/general/Sales.vue'),
    },
    {
      path: 'order/list',
      name: 'Order/List',
      component: () => import('@/views/order/List.vue'),
    },
    {
      path: 'order/details/:orderId',
      name: 'Order/View',
      component: () => import('@/views/order/Details.vue'),
    },
    {
      path: 'order/details',
      name: 'Order/View',
      component: () => import('@/views/order/Details.vue'),
    },
    {
      path: 'order/meca-pro/:orderId',
      name: 'Order/MecaPro',
      component: () => import('@/views/order/MecaPro.vue'),
    },
    {
      path: 'order/preorder/agreements/create',
      name: 'Order/PreOrder/Agreements/Create',
      component: () => import('@/views/order/preorder/agreements/Create.vue'),
    },
    {
      path: 'order/preorder/agreements/list',
      name: 'Order/PreOrder/Agreements/List',
      component: () => import('@/views/order/preorder/agreements/List.vue'),
    },
    {
      path: 'order/preorder/agreements/edit/:id',
      name: 'Order/PreOrder/Agreements/Edit',
      component: () => import('@/views/order/preorder/agreements/Create.vue'),
    },
    {
      path: 'economy/automate_invoicing/config',
      name: 'Economy/AutomateInvoicing/Config',
      component: () => import('@/views/economy/automate-invoicing/Config.vue'),
    },
    {
      path: 'economy/accounting-integration/reconcile-customer-balances',
      name: 'Economy/AccountingIntegration/ReconcileCustomerBalances',
      component: () => import('@/views/integrations/accounting/ReconcileCustomerBalances.vue'),
    },
    {
      path: 'settings/economy/accounting-integration/status',
      name: 'Settings/Economy/AccountingIntegration/Status',
      component: () => import('@/views/integrations/accounting/Status.vue'),
    },
    {
      path: 'settings/economy/accounting-integration/status/:id',
      name: 'Settings/Economy/AccountingIntegration/Status/Details',
      component: () => import('@/views/integrations/accounting/StatusDetails.vue'),
    },
    {
      path: 'settings/economy/accounting-integration/setup/overview',
      name: 'Settings/Economy/AccountingIntegration/Setup',
      component: () => import('@/views/economy/accounting-integration/Setup.vue'),
    },
    {
      path: 'settings/economy/accounting-integration/setup/:page',
      name: 'Settings/Economy/AccountingIntegration/Setup/Page',
      component: () => import('@/views/economy/accounting-integration/Setup.vue'),
    },
    {
      path: 'settings/tyre/tread_depth_thresholds',
      name: 'Settings/Tyre/TreadDepthThresholds',
      component: () => import('@/views/tyre/TreadDepthThresholdsConfig.vue'),
    },
    {
      path: 'economy/invoice-delivery-log',
      name: 'Economy/InvoiceDeliveryLog',
      component: () => import('@/views/economy/invoice-delivery-log/InvoiceDeliveryList.vue'),
    },
    {
      path: 'settings/economy/sales-accounting-rules/new',
      name: 'Settings/Economy/SalesAccountingRules/New',
      component: () => import('@/views/economy/sales-accounting-rules/Create.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/sales-accounting-rules/list',
      name: 'Settings/Economy/SalesAccountingRules/List',
      component: () => import('@/views/economy/sales-accounting-rules/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/sales-accounting-rules/edit/:id',
      name: 'Settings/Economy/SalesAccountingRules/Edit',
      component: () => import('@/views/economy/sales-accounting-rules/Create.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/payment-accounting-rules/list',
      name: 'Settings/Economy/PaymentAccountingRules/List',
      component: () => import('@/views/economy/payment-accounting-rules/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/payment-accounting-rules/new',
      name: 'Settings/Economy/PaymentAccountingRules/New',
      component: () => import('@/views/economy/payment-accounting-rules/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/economy/payment-accounting-rules/edit/:id',
      name: 'Settings/Economy/PaymentAccountingRules/Edit',
      component: () => import('@/views/economy/payment-accounting-rules/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/cost-accounting-rules/list',
      name: 'Settings/Economy/CostAccountingRules/List',
      component: () => import('@/views/economy/cost-accounting-rules/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/cost-accounting-rules/new',
      name: 'Settings/Economy/CostAccountingRules/New',
      component: () => import('@/views/economy/cost-accounting-rules/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/economy/cost-accounting-rules/edit/:id',
      name: 'Settings/Economy/CostAccountingRules/Edit',
      component: () => import('@/views/economy/cost-accounting-rules/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/accounts/list',
      name: 'Settings/Economy/Accounts/List',
      component: () => import('@/views/economy/accounts/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/accounts/new',
      name: 'Settings/Economy/Accounts/New',
      component: () => import('@/views/economy/accounts/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/economy/accounts/edit/:id',
      name: 'Settings/Economy/Accounts/Edit',
      component: () => import('@/views/economy/accounts/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/vat-codes/list',
      name: 'Settings/Economy/VatCodes/List',
      component: () => import('@/views/economy/vat-codes/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/vat-codes/new',
      name: 'Settings/Economy/VatCodes/New',
      component: () => import('@/views/economy/vat-codes/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/economy/vat-codes/edit/:id',
      name: 'Settings/Economy/VatCodes/Edit',
      component: () => import('@/views/economy/vat-codes/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/cost-centers/config',
      name: 'Settings/Economy/CostCenters/Config',
      component: () => import('@/views/economy/cost-centers/Config.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/cost-centers/list',
      name: 'Settings/Economy/CostCenters/List',
      component: () => import('@/views/economy/cost-centers/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/economy/cost-centers/new',
      name: 'Settings/Economy/CostCenters/New',
      component: () => import('@/views/economy/cost-centers/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/economy/cost-centers/edit/:id',
      name: 'Settings/Economy/CostCenters/Edit',
      component: () => import('@/views/economy/cost-centers/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/economy/settings/SideBar.vue') },
    },
    {
      path: 'settings/logistics/notification',
      name: 'Settings/Logistics/Notification',
      component: () => import('@/views/logistics/Notification.vue'),
    },
    {
      path: 'settings/customer/consent/list/:consentType',
      name: 'Settings/Customer/Consent/List',
      component: () => import('@/views/customers/consent/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/customer/settings/consent/SideBar.vue') },
    },
    {
      path: 'settings/customer/consent/new/:consentType',
      name: 'Settings/Customer/Consent/New',
      component: () => import('@/views/customers/consent/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/customer/settings/consent/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/customer/consent/edit/:consentType/:id',
      name: 'Settings/Customer/Consent/Edit',
      component: () => import('@/views/customers/consent/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/customer/settings/consent/SideBar.vue') },
    },
    {
      path: 'support/site/contact',
      name: 'Support/Site/Contact',
      component: () => import('@/views/support/SiteContact.vue'),
    },
    {
      path: 'cars',
      name: 'Cars/List',
      component: () => import('@/views/car/List.vue'),
    },
    {
      path: 'drivers',
      name: 'Drivers/List',
      component: () => import('@/views/car/driver/List.vue'),
    },
    {
      path: 'car/tread-depth-readings',
      name: 'Car/TreadDepthReadings',
      component: () => import('@/views/car/tread-depth-reading/List.vue'),
    },
    {
      path: 'car/tread-depth-readings/:id/edit',
      name: 'Car/TreadDepthReading/Edit',
      component: () => import('@/views/car/tread-depth-reading/Edit.vue'),
    },
    {
      path: 'car/tread-depth-readings/:id/show',
      name: 'Car/TreadDepthReading/Show',
      component: () => import('@/views/car/tread-depth-reading/Show.vue'),
    },
    {
      path: 'settings/booking/:calendarId/services',
      name: 'Settings/Booking/Services',
      component: () => import('@/views/booking/settings/service/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/services/:id/edit',
      name: 'Settings/Booking/Services/Edit',
      component: () => import('@/views/booking/settings/service/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/addon-services',
      name: 'Settings/Booking/AddonServices',
      component: () => import('@/views/booking/settings/service/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue'), isAddonService: true },
    },
    {
      path: 'settings/booking/:calendarId/addon-services/:id/edit',
      name: 'Settings/Booking/AddonServices/Edit',
      component: () => import('@/views/booking/settings/service/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue'), isAddonService: true },
    },
    {
      path: 'settings/booking/:calendarId/service-categories',
      name: 'Settings/Booking/ServiceCategories',
      component: () => import('@/views/booking/settings/service-category/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/service-categories/:id/edit',
      name: 'Settings/Booking/ServiceCategories/Edit',
      component: () => import('@/views/booking/settings/service-category/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/schedules',
      name: 'Settings/Booking/Schedules',
      component: () => import('@/views/booking/settings/schedule/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/schedules/:id/edit',
      name: 'Settings/Booking/Schedules/Edit',
      component: () => import('@/views/booking/settings/schedule/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/resources',
      name: 'Settings/Booking/Resources',
      component: () => import('@/views/booking/settings/resource/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/resources/:id/edit',
      name: 'Settings/Booking/Resources/Edit',
      component: () => import('@/views/booking/settings/resource/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/places',
      name: 'Settings/Booking/Places',
      component: () => import('@/views/booking/settings/place/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/places/:id/edit',
      name: 'Settings/Booking/Places/Edit',
      component: () => import('@/views/booking/settings/place/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/calendars/:calendarId',
      name: 'Settings/Booking/Calendars',
      component: () => import('@/views/booking/settings/calendar/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/calendars/:calendarId/edit',
      name: 'Settings/Booking/Calendars/Edit',
      component: () => import('@/views/booking/settings/calendar/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/calendars/:calendarId/new',
      name: 'Settings/Booking/Calendars/New',
      component: () => import('@/views/booking/settings/calendar/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/booking/:calendarId/planning',
      name: 'Settings/Booking/Planning',
      component: () => import('@/views/booking/settings/planning/Main.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/portals',
      name: 'Settings/Booking/Portals',
      component: () => import('@/views/booking/settings/portal/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/portals/:id/edit',
      name: 'Settings/Booking/Portals/Edit',
      component: () => import('@/views/booking/settings/portal/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/portals/0/new',
      name: 'Settings/Booking/Portals/New',
      component: () => import('@/views/booking/settings/portal/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/booking/:calendarId/reminders',
      name: 'Settings/Booking/Reminders',
      component: () => import('@/views/booking/settings/reminder/List.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/reminders/:id/edit',
      name: 'Settings/Booking/Reminders/Edit',
      component: () => import('@/views/booking/settings/reminder/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue') },
    },
    {
      path: 'settings/booking/:calendarId/reminders/0/new',
      name: 'Settings/Booking/Reminders/New',
      component: () => import('@/views/booking/settings/reminder/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'settings/booking/:calendarId/reminders/0/new/:type/:transport',
      name: 'Settings/Booking/Reminders/NewForType',
      component: () => import('@/views/booking/settings/reminder/Edit.vue'),
      meta: { sidebar: () => import('@/components/sidebars/booking/settings/SideBar.vue'), isNew: true },
    },
    {
      path: 'bookings',
      name: 'Booking/List',
      component: () => import('@/views/booking/List.vue'),
    },
    {
      path: 'bookings/new',
      name: 'Booking/New',
      component: () => import('@/views/booking/New.vue'),
    },
    {
      path: 'bookings/calendar',
      name: 'Booking/Calendar',
      component: () => import('@/views/booking/Calendar.vue'),
    },
    {
      path: 'bookings/debug-times',
      name: 'Booking/DebugTimes',
      component: () => import('@/views/booking/DebugTimes.vue'),
    },
    {
      path: 'bookings/new',
      name: 'Booking/New',
      component: () => import('@/views/booking/New.vue'),
    },
    {
      path: 'bookings/new/:referenceType/:referenceId',
      name: 'Booking/New',
      component: () => import('@/views/booking/New.vue'),
    },
    {
      path: 'bookings/new/:referenceType/:referenceId/:referenceParam',
      name: 'Booking/NewWithReferenceParam',
      component: () => import('@/views/booking/New.vue'),
    },
    {
      path: 'bookings/:id/modify',
      name: 'Booking/Modify',
      component: () => import('@/views/booking/New.vue'),
    },
    {
      path: 'bookings/:id',
      name: 'Booking/Show',
      component: () => import('@/views/booking/Show.vue'),
    },
    {
      path: 'auto-bookings',
      name: 'AutoBooking/List',
      component: () => import('@/views/booking/auto-booking/List.vue'),
    },
    {
      path: 'auto-bookings/new',
      name: 'AutoBooking/New',
      component: () => import('@/views/booking/auto-booking/Edit.vue'),
      meta: { isNew: true },
    },
    {
      path: 'auto-bookings/:id',
      name: 'AutoBooking/Edit',
      component: () => import('@/views/booking/auto-booking/Edit.vue'),
    },
    {
      path: 'price/manager/:marginType/:marginSubType',
      name: 'Price/Manager/List',
      component: () => import('@/views/price/manager/List.vue'),
    },
    {
      path: 'price/rules/edit/:id',
      name: 'Price/Manager/Edit',
      component: () => import('@/views/price/manager/Edit.vue'),
    },
    {
      path: 'price/rules/new/:marginType/:referenceId',
      name: 'Price/Manager/New',
      component: () => import('@/views/price/manager/Edit.vue'),
    },
    {
      path: 'supplier/warehouse/list',
      name: 'Supplier/Location/List',
      component: () => import('@/views/supplier/location/List.vue'),
    },
    {
      path: 'supplier/reference',
      name: 'Supplier/Reference',
      component: () => import('@/views/supplier/Reference.vue'),
    },
    {
      path: 'supplier/product/debug',
      name: 'Supplier/Product/Debug',
      component: () => import('@/views/supplier/product/Debug.vue'),
    },
    {
      path: 'product/type/list',
      name: 'Product/Type/List',
      component: () => import('@/views/product/type/List.vue'),
    },
    {
      path: 'sysop/i18n',
      name: 'Sysop/I18n',
      component: () => import('@/views/sysop/i18n/Main.vue'),
    },
    {
      path: 'sysop/billing',
      name: 'Sysop/Billing',
      component: () => import('@/views/sysop/billing/Main.vue'),
    },
    {
      path: 'sysop/billing/settlement/:id',
      name: 'Sysop/Billing/Settlement',
      component: () => import('@/views/sysop/billing/Settlement.vue'),
    },
    {
      path: 'sysop/request-api-logs',
      name: 'Sysop/Api/Request/Logs',
      component: () => import('@/views/sysop/api-request-logs/List.vue'),
    },
    {
      path: 'sysop/request-api-logs/statistics',
      name: 'Sysop/Api/Request/Logs/Statistics',
      component: () => import('@/views/sysop/api-request-logs/Statistics.vue'),
    },
    {
      path: 'payment-type/rounding-mode',
      name: 'PaymentType/RoundingMode',
      component: () => import('@/views/payment/payment-type-x-rounding-mode/List.vue'),
    },
    {
      path: 'payment/group-invoice-reminders',
      name: 'Payment/GroupInvoiceReminders/Customers',
      component: () => import('@/views/payment/group-invoice-reminder/GroupInvoiceReminderCustomers.vue'),
    },
    {
      path: 'payment/group-invoice-reminders/:id',
      name: 'Payment/GroupInvoiceReminders/Customer',
      component: () => import('@/views/payment/group-invoice-reminder/GroupInvoiceReminderCustomer.vue'),
    },
    {
      path: 'payment/cash-register/cash-transactions',
      name: 'Payment/CashRegister/CashTransactions/List',
      component: () => import('@/views/payment/cash-register/cash-transactions/List.vue'),
    },
    {
      path: 'payment/cash-register/saft-export',
      name: 'Payment/CashRegister/CashTransactions/SaftExport',
      component: () => import('@/views/payment/cash-register/SaftExport.vue'),
    },
    {
      path: 'notification/list',
      name: 'Notification/List',
      component: () => import('@/views/notification/List.vue'),
    },
    {
      path: 'tools/alias/naming',
      name: 'Tools/Alias/Naming',
      component: () => import('@/views/tools/alias/List.vue'),
    },
    {
      path: 'tags',
      name: 'Tags/List',
      component: () => import('@/views/tag/List.vue'),
    },
    {
      path: 'reports/:name',
      name: 'Reports/Generic',
      component: () => import('@/views/reports/generic-reports/GenericReport.vue'),
    },
    {
      path: 'auto-products/list',
      name: 'AutoProducts/List',
      component: () => import('@/views/auto-product/List.vue'),
    },
    {
      path: 'auto-products/edit/:id',
      name: 'AutoProducts/Edit',
      component: () => import('@/views/auto-product/NewOrEdit.vue'),
    },
    {
      path: 'auto-products/new',
      name: 'AutoProducts/New',
      component: () => import('@/views/auto-product/NewOrEdit.vue'),
    },
    {
      path: 'reports/medius/list-pending-orders',
      name: 'Reports/Medius/ListPendingOrders',
      component: () => import('@/views/reports/medius/ListPendingOrders.vue'),
    },
    {
      path: ':legacyUrl*',
      name: 'LegacyIFrame',
      component: () => import('@/components/VueFrame.vue'),
    },
  ] as RouteConfig<string>[]
).map((route) => ({
  ...route,
  meta: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...(route.meta || {}),
    requireAuth: true,
  },
}))

const routes = [
  {
    path: '/',
    component: RouterLayout,
    children: [
      {
        path: '/:siteId(\\d+)/',
        component: {
          render: (h) => h('RouterView'),
        },
        children: [...unauthenticatedRoutes, ...authenticatedRoutes],
      },
      ...unauthenticatedRoutes.map((route) => ({
        ...route,
        name: `NoSite/${route.name}`,
      })),
      {
        path: '/choose-site',
        name: 'ChooseSite',
        component: () => import('@/views/ChooseSite.vue'),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: '*',
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
]

const router = new Router({
  routes,
  mode: 'history',
})

router.onError((error) => {
  if (/Loading chunk chunk-.* failed./i.test(error.message)) {
    console.error('Loading chunk error:', error)
    checkBuild()
  }
})

guards(router)

export default router
